/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Arka planı biraz koyulaştırarak modalı vurgula */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Modal */
  .modal {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Hafif bir gölgelendirme */
    padding: 20px;
  }
  
  /* Modal Content */
  .modal-content {
    /* İçeriğinizi istediğiniz gibi stilize edin */
  }
  
  /* Modal Header */
  .custom-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .custom-modal-title {
    margin: 0;
  }
  
  .custom-modal-close {
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  /* Modal Footer */
  .custom-modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .custom-modal-footer button {
    margin-left: 10px;
  }