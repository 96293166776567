@import "node_modules/govuk-frontend/govuk/all";

// ios safari scroll on focus fix
// html,
// body {
//     -webkit-overflow-scrolling: touch !important;
//     overflow: auto !important;
//     height: 100% !important;
// }
html {
    overflow-y: scroll;
}

// date
.govuk-date-input__item {
    vertical-align: top;
}

// lists
.govuk-list--tick li:before {
    content: "✓";
    color: #00703c;
    font-size: large;
    margin-right: 12px;
}

// tabs
.govuk-tabs__list-item--selected::before {
    content: none;
}

.govuk-tabs__list-item {
    padding-top: 6px;
}

.govuk-tabs__list-item--selected {
    background-color: #1d70b8;
    margin-left: -10px;
    padding-left: 32px;
}

.govuk-tabs__tab {
    width: 100%;
}

.govuk-tabs__list-item--selected>.govuk-tabs__tab {
    color: #fff;
}

.govuk-tabs__tab:focus {
    background-color: unset !important;
    border-top: none !important;
    outline: none !important;
    box-shadow: none !important;
}

// hightlight on hover
.highlightOnHover:hover {
    color: #fff;
    background-color: #1d70b8;
    cursor: pointer;
}

// Task list pattern

.app-task-list {
    list-style-type: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;

    @include govuk-media-query($from: tablet) {
        min-width: 550px;
    }
}

.app-task-list__section {
    display: table;
    @include govuk-font($size: 24, $weight: bold);
}

.app-task-list__section-number {
    display: table-cell;

    @include govuk-media-query($from: tablet) {
        min-width: govuk-spacing(6);
        padding-right: 0;
    }
}

.app-task-list__items {
    @include govuk-font($size: 19);
    @include govuk-responsive-margin(9, "bottom");
    list-style: none;
    padding-left: 0;

    @include govuk-media-query($from: tablet) {
        padding-left: govuk-spacing(6);
    }
}

.app-task-list__item {
    border-bottom: 1px solid $govuk-border-colour;
    margin-bottom: 0 !important;
    padding-top: govuk-spacing(2);
    padding-bottom: govuk-spacing(2);
    @include govuk-clearfix;
}

.app-task-list__item:first-child {
    border-top: 1px solid $govuk-border-colour;
}

.app-task-list__task-name {
    display: block;

    @include govuk-media-query($from: 450px) {
        float: left;
    }
}

// The `app-task-list__task-completed` class was previously used on the task
// list for the completed tag (changed in 86c90ec) – it's still included here to
// avoid breaking task lists in existing prototypes.
.app-task-list__tag,
.app-task-list__task-completed {
    margin-top: govuk-spacing(2);
    margin-bottom: govuk-spacing(1);

    @include govuk-media-query($from: 450px) {
        float: right;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.govuk-header__container {
    margin-bottom: 0;
    border: 0;
}

.govuk-header {
    background-color: #154194;
}

.govuk-footer {
    margin-top: auto;
    color: #fff;
    background-color: #0080C8;
}

._govuk-button:not(.govuk-button--secondary) {
    background-color: #8FC93A;
    box-shadow: 0 2px 0 #00703c;
}

body {
    margin: 8px;
}

.govuk-width-container {
    width: 100%;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 16px);
}